.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiPaper-rounded, .MuiButton-root {
  border-radius: 3px !important;
}

html, body, #root {
  height: 100%;
}

p {
  -webkit-font-smoothing: antialiased;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAA;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #AAA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #AAA;
}

.MuiTableCell-root {
  border-bottom: 10px solid #FAFAFF !important;
  padding: 10px !important;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid #CCCCCC !important;
} 

.editPage label {
  margin-bottom: 6px;
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: black;
}

.editPage input {
  width: 100%;
  font-size: 14px;
  height: 2Opx;
}

.editPage .MuiFormControl-root {

  width: 100%;
  font-size: 14px;
  height: 2Opx;
  padding: 4px 10px;
  background-color: white;
}

.editPage .MuiFormControl-root .MuiInput-formControl {
  background-color: white;
  border-bottom: none;
}

.editPage .MuiInput-underline:before {
  border-bottom: none;
}

.specialityPaper {
  padding: 1px 10px;
  margin: 10px;
}

.topBar {
  height: 90px;
  padding: 10px;
  background: rgba(255,255,255,0.90);
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
}

.topBar .searchPaper {
  width: 100%;
  border: 1px solid #DEE8EC;
  border-radius: 3px;
  box-shadow: none;
  padding: 2px 8px;
  box-shadow: none;
}
.searchInput {
  width: 100%;
}

.topBar button.addButton {
  background-image: linear-gradient(92deg, #FF1C57 0%, #8E005A 100%);
  padding: 10px 30px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}

.topBar button.cancelButton {
  padding: 10px 30px;
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: normal;
  margin-right: 30px;
  border: 1px solid #333;
}

.editPage .MuiTextField-root, .editPage .MuiSelect-root {
  border: 1px solid #DEE8EC;
  border-radius: 3px;
  background-color: white;
}

.editPage .MuiSelect-root {
  height: 28px;
}
.editPage .MuiInputBase-inputSelect {
  padding: 8px 20px 0px 4px;
  width: 100% !important;
}

.editPage .specialityPaper .MuiGrid-root {
  margin: 4px 0 4px 0;
}

.editPage fieldset.MuiFormControl-root  {
  background-color: inherit;
}